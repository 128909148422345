import * as React from "react"
import { Helmet } from "react-helmet"
import "../css/bootstrap.min.css"
import "../css/styles.css"
import rollinsLogo from "../images/rollins_electrical_ltd_dark.svg"
import NICLogo from "../images/niceic_approved_contractor.svg"

// markup
const IndexPage = () => {
  return (
    <div className="container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Rollins Electrical Ltd</title>
          <link rel="canonical" href="https://www.rollinselectrical.co.uk" />
      </Helmet>
      <div className="row justify-content-center py-3">
        <div className="col-8 col-sm-10 col-lg-8">
          <h1 className='d-none'>Rollins Electrical</h1>
          <img src={rollinsLogo} alt="Rollins Electrical Logo"  />
        </div>
      </div>
      <div className="row justify-content-center py-2">
        <div className="col-10 col-sm-12 col-lg-8 text-center">
          <h2>The website is currently undergoing a full rewire, please contact us on <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#114;&#111;&#108;&#108;&#105;&#110;&#115;&#101;&#108;&#101;&#99;&#116;&#114;&#105;&#99;&#97;&#108;&#46;&#99;&#111;&#46;&#117;&#107;">&#105;&#110;&#102;&#111;&#64;&#114;&#111;&#108;&#108;&#105;&#110;&#115;&#101;&#108;&#101;&#99;&#116;&#114;&#105;&#99;&#97;&#108;&#46;&#99;&#111;&#46;&#117;&#107;</a></h2>
        </div>
      </div>
      <div className="row justify-content-center py-3">
        <div className="col-2 col-sm-4 col-lg-3">
          <img src={NICLogo} alt="NICEIC Approved Contractor Logo" />
        </div>
      </div>
      <div className="row justify-content-center py-3">
        <div className="col-6 text-center">
          <p>&copy; <span id="year"></span> Rollins Electrical Ltd</p>
        </div>
      </div>
    </div>
  )
}

export default IndexPage
